import React from "react";
import { FaCheck } from "react-icons/fa";
import { Row, Col } from "react-bootstrap";

const ListCheckItem = ({children}) => {
    return (
        <li>
            <Row className="align-items-start">
                <Col className="col-auto">
                    <span><i><FaCheck/></i></span>
                </Col>
                <Col>
                    <div style={{padding:8}}>
                        {children}
                    </div>
                </Col>
            </Row>
        </li>
    )
}

export default ListCheckItem;